<template>
  <div class="edittable container-fluid">

    <div class="row py-2">
      <slot name="title">
        <span>Table Title</span>
      </slot>
      <span class="cmd_btn text-right ml-auto" v-if="showControl">
        <button class="btn btn-sm btn-outline-primary" @click="$refs.listTable.refresh(); $emit('refresh');" :disabled="busy" title="Refresh table"><RefreshIcon /></button>
        <button class="import_btn btn btn-sm btn-outline-primary" @click="openFileDialog" :disabled="uploading" title="Upload from CSV">
          <b-spinner small v-if="uploading" />
          <template v-else>
            <UploadIcon />
            <input ref="csv_file_input" class="d-none" type="file" name="file" accept=".csv,text/csv" @change="importFromCsv($event.target.files)" />
          </template>
        </button>
        <button class="btn btn-sm btn-outline-primary" @click="downloadAll" :disabled="downloading" title="Download All as CSV">
          <b-spinner small v-if="downloading" />
          <DownloadAllIcon v-else />
        </button>
        <button class="btn btn-sm btn-outline-primary" @click="downloadSelected" :disabled="!selectedItems.length" title="Download selected items as CSV"><DownloadSelectedIcon /></button>
        <button class="btn btn-sm btn-outline-danger" @click="deleteSelected" :disabled="!selectedItems.length" title="Delete selected items"><DeleteSelectedIcon /></button>
        <button class="btn btn-sm btn-success" @click="$emit('addNew')" title="Add a new item"><AddNewIcon /> Add New</button>
      </span>
    </div>

    <div class="row bg-light py-2">
      <b-input-group class="col-sm col-md-7" size="sm">
        <b-form-input v-model="filter" type="text" placeholder="Type to filter" />
        <b-input-group-append>
          <b-button :disabled="!filter" @click="(filter = '')"><strong>&times;</strong></b-button>
        </b-input-group-append>
      </b-input-group>

      <b-input-group class="col-sm col-md-5 mt-2 mt-sm-0" size="sm" v-if="sortOptionFields.length">
        <b-form-select v-model="sortBy" :options="sortOptionFields" class="w-75">
          <template v-slot:first>
            <option value="">-- sort by --</option>
          </template>
        </b-form-select>
        <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
          <option :value="false">Asc</option>
          <option :value="true">Desc</option>
        </b-form-select>
      </b-input-group>
    </div>

    <div class="row">
      <b-table ref="listTable" v-bind="$attrs" v-on="$listeners" responsive show-empty
        v-model="visibleItems"
        :fields="headerFields"
        :items="items"
        :selectable="selectable"
        :tbody-transition-props="tbodyTransitionProps"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="busy"
        @filtered="onFiltered"
        @row-selected="onRowSelected"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-2" />
            <span>Loading...</span>
          </div>
        </template>

        <template v-slot:head(checkbox)="head">
          <b-form-checkbox v-model="allRowsSelected" @change="selectAllRows(head)" />
        </template>

        <template v-slot:cell(checkbox)="cell">
          <b-form-checkbox v-model="cell.rowSelected" @change="selectRow(cell)" />
        </template>

        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
          <slot :name="name" v-bind="slotData" />
        </template>

      </b-table>
    </div>

    <div class="row">
      <span v-if="selectedItems.length">{{ selectedItems.length }} rows selected</span>
      <b-pagination class="my-0 ml-auto" size="sm" v-model="currentPage" :per-page="perPage" :total-rows="totalRows" align="right" v-show="(totalRows > perPage)" />
    </div>

  </div>
</template>

<script>
import RefreshIcon from 'mdi-vue/TableRefresh';
import UploadIcon from 'mdi-vue/UploadMultiple';
import DownloadAllIcon from 'mdi-vue/DownloadMultiple';
import DownloadSelectedIcon from 'mdi-vue/Download';
import DeleteSelectedIcon from 'mdi-vue/DeleteForeverOutline';
import AddNewIcon from 'mdi-vue/PlaylistPlus';
import { capitalizeFirstLetter, strSortCaseInsensitive } from '@/util/string.helper';
import {
  convertJson2Csv, convertCsv2Json, writeCsv2File, readFileContent, getFileExtention,
} from '@/util/file.helper';

export default {
  name: 'EditTable',
  inheritAttrs: false,

  components: {
    RefreshIcon, UploadIcon, DownloadAllIcon, DownloadSelectedIcon, DeleteSelectedIcon, AddNewIcon,
  },

  props: {
    /* props from BootStrapVue Table */
    busy: Boolean,
    fields: { type: Array, default: null },
    selectable: { type: Boolean, default: false },
    perPage: { type: Number, default: 10 },
    items: {
      type: [Array, Function],
      default() { return []; },
    },
    tbodyTransitionProps: {
      type: Object,
      default() { return { name: 'flip-list' }; },
    },

    /* props unique to this component */

    // toggle the display of top control buttons
    showControl: { type: Boolean, default: true },
    // default name to use as the exported filename when download button is clicked
    downloadFilename: { type: String, default: null },
    // additional fields that will be included in sort option dropdown. Expected format: [{ key: 'a', label: 'A' }, ..]
    sortFields: {
      type: Array,
      default() { return []; },
    },
  },

  data() {
    return {
      allRowsSelected: false,
      visibleItems: [],
      selectedItems: [],
      filteredItems: [],
      currentPage: 1,
      totalRows: 0,
      filter: null,
      sortBy: '',
      sortDesc: false,
      uploading: false,
      downloading: false,
    };
  },

  computed: {
    headerFields() {
      let { fields } = this;
      if (this.showControl && this.selectable && fields) fields = ['checkbox', ...fields];
      return fields;
    },
    sortOptionFields() {
      let fields = [];
      if (this.fields) fields = this.fields.filter(f => f.sortable);
      fields = fields.concat(this.sortFields);
      return fields.map(f => ({ text: capitalizeFirstLetter(f.label || f.key), value: f.key })).sort((a, b) => strSortCaseInsensitive(a.text, b.text));
    },
  },

  methods: {
    selectAllRows(rows) {
      if (rows) {
        if (this.allRowsSelected) rows.clearSelected();
        else rows.selectAllRows();
      }
      this.allRowsSelected = !this.allRowsSelected;
    },

    selectRow(row) {
      if (row) {
        if (row.rowSelected) row.unselectRow(row.index);
        else row.selectRow(row.index);
      }
    },

    onRowSelected(item) {
      this.selectedItems = item || [];
      this.allRowsSelected = (this.selectedItems.length >= this.visibleItems.length);
    },

    onFiltered(filteredItems) {
      if (filteredItems) {
        this.totalRows = filteredItems.length;
        // keep a filteredItems list only when there are filtered items
        this.filteredItems = (this.items.length > this.totalRows) ? filteredItems : [];
      }
      this.currentPage = 1;
    },

    downloadAll() {
      const items = this.filteredItems.length ? this.filteredItems : this.items;
      this.downloadAsCSV(items);
    },

    downloadSelected() {
      this.downloadAsCSV(this.selectedItems);
    },

    downloadAsCSV(items) {
      this.downloading = true;
      if (items && items.length) {
        const filename = this.downloadFilename || `items_${Date.now()}.csv`;
        writeCsv2File(convertJson2Csv(items), filename);
      }
      this.downloading = false;
    },

    openFileDialog() {
      const fileInput = this.$refs.csv_file_input;
      if (fileInput) {
        fileInput.value = '';
        fileInput.click();
      }
    },

    importFromCsv(files) {
      if (files && files.length) {
        const fileExt = getFileExtention(files[0].name).toLowerCase();
        if (fileExt !== 'csv') this.$toast.error('Only CSV files with \'.csv\' extensions are supported.', 'Invalid File');
        else {
          this.uploading = true;
          readFileContent(files[0])
            .then(async (csvContent) => {
              const json = await convertCsv2Json(csvContent);
              this.$emit('uploadCsv', json);
            })
            .catch(() => { /* ignore */ })
            .finally(() => {
              this.uploading = false;
            });
        }
      }
    },

    deleteSelected() {
      const itemsCnt = this.selectedItems.length;
      this.$bvModal.msgBoxConfirm(`${itemsCnt} item(s) will be deleted. Are you sure?`, {
        buttonSize: 'sm', centered: true, okVariant: 'danger', okTitle: 'Yes', cancelTitle: 'No',
      })
        .then((reply) => {
          if (reply === true) this.$emit('deleteSelected', this.selectedItems);
        })
        .catch(() => { /* ignore */ });
    },
  },

  mounted() {
    this.totalRows = this.items.length;
  },

};
</script>

<style lang="stylus">

.edittable
  .cmd_btn .btn
    margin-left 0.3rem
  .flip-list-move
    transition transform 0.5s

</style>
